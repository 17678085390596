import Image from "next/image"

import adsTerraGif from '/public/assets/images/adsterra.gif'

const ReferralBanner = () => {
  return (
    <a href="https://beta.publishers.adsterra.com/referral/bsGuC6frPr" target='_blank' className='adsTerra'>
      <Image src={adsTerraGif} alt="AdsTerra, Junte-se ao AdsTerra" width={728} height={90} />
    </a>
  )
}

export { ReferralBanner }