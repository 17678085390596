export const navData = [
  {
    id: 1,
    label: 'Explore',
    link: '/tag/explore'
  },
  {
    id: 2,
    label: 'News',
    link: '/tag/news'
  },
  {
    id: 3,
    label: 'Frontend',
    link: '/tag/frontend'
  },
  {
    id: 4,
    label: 'Backend',
    link: '/tag/backend'
  }
]