import Link from "next/link"

import styles from './footer.menu.module.scss'

import googleSafe from '../../../public/assets/logos/google-safe.png'
import Image from "next/image"

const FooterMenu = () => {
  return (
    <div className={styles.siteLinks}>
      <p><strong>Nossas políticas</strong></p>

      <ul>
        <li>
          <Link href="/politica-de-privacidade" className="linkHover">Política de Privacidade</Link>
        </li>
        <li>
          <Link href="/politica-de-cookies" className="linkHover">Política de Cookies</Link>
        </li>
        <li>
          <Link href="/termos-e-condicoes" className="linkHover">Termos</Link>
        </li>
        <li>
          <Link href="/contato" className="linkHover">Contato</Link>
        </li>
      </ul>

      <a href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fadevindev.xyz%2F" target="_blank" rel="noopener noreferrer" title="Selo Google Safe Browsing" className={styles.googlesf}>
        <Image src={googleSafe} width={109} height={32} alt="Selo Google Safe Browsing" />
      </a>
    </div>
  )
}

export { FooterMenu }