import { SocialLinks } from "../SocialLinks";
import { FooterMenu } from "../FooterMenu";
import { Cookies } from "../Cookies";
import { Copy } from "../Copy";
import { ReferralBanner } from "../AdsTerra";

const Footer = () => {
  return (
    <footer className="footer">
      <ReferralBanner />

      <section className="container">
        <div className="content">
          <SocialLinks />

          <FooterMenu />

          <Copy />
        </div>

        <Cookies />
      </section>
    </footer>
  );
};

export { Footer };
