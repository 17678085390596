import { useEffect, useState } from 'react'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Footer } from '../Footer'
import { Header } from '../Header'

import styles from './layout.module.scss'

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  },[isLoading])

  return (
    <>
      <Header />

      <main className={`container ${styles.main}`}>
        {isLoading ? (
          <Skeleton height="calc(100vh / 3)" count={3} />
          ) : (
            <>
              {children}
            </>
        )}
      </main>
      
      <Footer />
    </>
  )
}

export { Layout }