import { useState, useEffect } from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import { useRouter } from "next/router"

import { Logo } from "../Logo"
import { Nav } from "../Nav"

import styles from './header.module.scss'
import { Search } from "../Search"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [withH1, setWithH1] = useState(true)
  const { asPath } = useRouter()

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setWithH1(asPath === "/")
  }, [asPath, withH1])

  return (
    <header className={styles.header}>
      <section className="container">
        <div className={styles.content}>
          <Logo withH1={withH1} />

          <div className={styles.toolbar}>
            <Search />
            <Nav toggle={isOpen} handleToggle={handleToggle} />
            <button type="button" className={styles.toggleMenu} onClick={handleToggle} title="Abrir menu mobile">
              <GiHamburgerMenu />
            </button>
          </div>
        </div>
      </section>
    </header>
  )
}

export { Header }