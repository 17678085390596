import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"

import styles from './sociallinks.module.scss'

const SocialLinks = ({ customClass }) => {
  return (
    <div className={customClass || styles.socialWrapper}>
      <a href="https://www.facebook.com/adevindev.xyz" title="Facebook" target="_blank" rel="noreferrer" className={`linkHover ${styles.socialLink}`}>
        <FaFacebookF />
      </a>

      <a href="https://www.instagram.com/adevindev/" title="Instagram" target="_blank" rel="noreferrer" className={`linkHover ${styles.socialLink}`}>
        <FaInstagram />
      </a>

      <a href="https://twitter.com/adevindev" title="Twitter" target="_blank" rel="noreferrer" className={`linkHover ${styles.socialLink}`}>
        <FaTwitter />
      </a>
    </div>
  )
}

export { SocialLinks }