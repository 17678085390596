import Image from "next/image"

import logoGarra from '../../../public/assets/logos/garra.digital.ico'

import styles from './copy.module.scss'

const Copy = () => {
  return (
    <div className={styles.copy}>
      <p>Copyrights 2023 | <strong>A dev in dev</strong> ®</p>
      <a href="https://garra.digital" aria-label="Visitar Garra Digital" target="_blank" rel="noreferrer">
        <Image src={logoGarra} alt="Garra Digital" width={18} height={18} />
        <strong>Garra Digital</strong>
      </a>
    </div>
  )
}

export { Copy }