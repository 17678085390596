const Popunder = () => {
  return (
    <script
      type="text/javascript"
      src="//nauseousrocketjosephine.com/74/df/a7/74dfa76fc84627bd734c03dd8f0a9a8b.js"
      id="popunder-ad"
      defer
    ></script>
  );
};

export { Popunder };
