import Link from "next/link"

const NavItem = ({ link, label, ...props }) => {
  return (
    <li {...props}>
      <Link href={link} className="linkHover">{label}</Link>
    </li>
  )
}

export { NavItem }