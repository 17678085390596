import { useEffect, useState } from "react"
import Link from "next/link"
import { BiCookie } from "react-icons/bi"

import styles from './cookies.module.scss'

const Cookies = () => {
  const [cookies, setCookies] = useState(false)

  const handleCookies = () => {
    localStorage.setItem('adid_cookies', 'accepted')
    setCookies(true)
  }

  useEffect(() => {
    const adid_cookies = localStorage?.getItem('adid_cookies')

    if(adid_cookies) {
      setCookies(true)
    }
  }, [cookies])

  if (cookies) return

  return (    
    <div className={styles.cookiesWrapper}>
      <p>
        <BiCookie />
        <span>Nós usamos cookies e outras tecnologias semelhantes para melhorar a experiência de navegação e recomendar conteúdo de seu interesse. Ao continuar navegando você concorda com a nossa <Link href="/politica-de-privacidade">política de privacidade</Link></span>
      </p>

      <button type="button" onClick={handleCookies}>Concordo</button>
    </div>
  )
}

export { Cookies }