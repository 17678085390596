import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { api } from "../../../services/api";
import { useSearch } from "../../context/SearchContext";

import styles from "./search.module.scss";

const Search = () => {
  const searhFormRef = useRef();

  const { setResults, setQuery, setIsLoading } = useSearch();

  const [terms, setTerms] = useState("");
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);
    setError(null);

    if (terms.trim() !== "") {
      localStorage.removeItem("searchResults");
      localStorage.removeItem("searchQuery");

      try {
        const response = await api.get("/api/search", {
          params: {
            terms,
          },
        });
        const { posts, next_page, page } = response.data;

        const encodedTerms = encodeURI(terms);

        setResults(posts);
        setQuery(terms);
        localStorage.setItem("searchResults", JSON.stringify(posts));
        localStorage.setItem("searchQuery", JSON.stringify(terms));

        setIsLoading(false);
        router.push(`/pesquisa?termo=${encodedTerms}`);
      } catch (error) {
        setError("Um erro ocorreu ao realizar a busca.");
      }
    } else {
      toast.error("Digite algo para pesquisar..");
    }

    setLoading(false);
  };

  useEffect(() => {
    const shouldPerformeSearch = async () => {
      const searchQuery = localStorage.getItem("searchQuery");

      let terms = "";
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has("termo") && !searchQuery) {
        terms = urlParams.get("termo");
        setTerms(terms);

        searhFormRef.current.requestSubmit();
      }
    };

    shouldPerformeSearch();
  }, [router]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={styles.formWrapper}
        ref={searhFormRef}
      >
        <input
          type="text"
          name="pesquisa"
          placeholder="Pesquisar..."
          onChange={(e) => setTerms(e.target.value)}
          value={terms}
          required
        />
        <button type="submit" title="Pesquisar" disabled={isLoading}>
          <FaSearch />
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export { Search };
