const SocialBar = () => {
  return (
    <script
      type="text/javascript"
      src="//nauseousrocketjosephine.com/d0/80/92/d08092659f0d60c01fd8e5c84de942b1.js"
      id="social-bar"
      defer
    ></script>
  );
};

export { SocialBar };
