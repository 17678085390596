import { IoMdClose } from "react-icons/io"

import { Logo } from "../Logo"
import { NavItem } from "../NavItem"
import { SocialLinks } from "../SocialLinks"

import { navData } from "./nav.data"

import styles from './nav.module.scss'

const Nav = ({ toggle, handleToggle }) => {
  return (
    <nav className={`${styles.nav}${toggle ? ` ${styles.open}`: ''}`}>
      <div className={styles.navHeaderMobile}>
        <Logo withH1={false} />

        <button type="button" title="Fechar menu mobile" onClick={handleToggle}>
          <IoMdClose />
          <span className="srOnly">Fechar menu mobile</span>
        </button>
      </div>

      <ul>
        {navData.map((navItem) => (
          <NavItem key={navItem.id} link={navItem.link} label={navItem.label} onClick={handleToggle} />
        ))}
      </ul>

      <SocialLinks customClass={styles.socialMobile} />
    </nav>
  )
}

export { Nav }