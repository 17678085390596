import Head from "next/head"

const SEO = ({
  description = 'A DEV IN DEV é um portal de notícias, dicas e tutoriais Frontend e Backend, fique por dentro do que há de melhor no mundo da programação web.',
  title,
  metaDescription,
  ogImage,
  pageURL,
  datePublished = '2023-01-05T08:00:00+08:00',
  dateModified
}) => {
  const metaKeyWords = "A DEV IN DEV dicas e tutoriais Frontend e Backend, o que há de melhor no mundo da programação web, a dev in dev, O melhor conteúdo da programação web está aqui, bora codar, in dev, dicas e tutoriais sobre Frontend e Backend, desenvolvimento web, criação de sites, criação de site, desenvolvedor web, desenvolvimento de sites, criação de sites profissionais, criação de site profissional, criar site, web designer, google in site, websites, landing pages, landpages, blog wordpress, blogs, Site Responsivo, front end e back end, back end front end, back end e front end, front developer, frontend, backend e frontend, front developer, curso front end, curso backend";

  const metaData = [
    {
      property: `og:image`,
      content: `${ogImage || process.env.NEXT_PUBLIC_SITE_URL+'og.png'}`
    },
    {
      property: `og:image:width`,
      content: `1200`
    },
    {
      property: `og:image:height`,
      content: `630`
    },
    {
      property: `og:title`,
      content: `${title || `O melhor conteúdo da programação web está aqui | A DEV IN DEV`}`,
    },
    {
      property: `og:description`,
      content: `${metaDescription || description}`,
    },
    {
      property: `og:url`,
      content: `${pageURL || process.env.NEXT_PUBLIC_SITE_URL}`,
    },
    {
      property: `og:type`,
      content: `NewsArticle`,
    },
    {
      property: `og:locale`,
      content: `pt_BR`,
    },
    {
      name: `description`,
      content: `${metaDescription || description}`,
    },
    {
      name: 'robots',
      content: 'index, follow'
    },
    {
      name: 'googlebot',
      content: 'index, follow'
    },
    {
      name: 'google-adsense-account',
      content: 'ca-pub-3658888375672329'
    },
    {
      name: 'keywords',
      content: `${metaKeyWords}`
    },
    {
      name: `image`,
      content: `${ogImage || process.env.NEXT_PUBLIC_SITE_URL+'og.png'}`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: 'A DEV IN DEV',
    },
    {
      name: `twitter:title`,
      content: `${title || `O melhor conteúdo da programação web está aqui | A DEV IN DEV`}`,
    },
    {
      name: `twitter:description`,
      content: `${metaDescription || description}`,
    },
  ]

  const addWebsiteSchema = () => {
    const jsonLD = {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": title || "O melhor conteúdo da programação web está aqui | A DEV IN DEV}",
      "image": ogImage || process.env.NEXT_PUBLIC_SITE_URL+"og.png",
      "datePublished": datePublished,
      "dateModified": dateModified || datePublished,
      "about": {
        "description": metaDescription || description
      },
      "url": pageURL || process.env.NEXT_PUBLIC_SITE_URL,
      "keywords": metaKeyWords,
      "thumbnailUrl": ogImage || process.env.NEXT_PUBLIC_SITE_URL+"og.png",
      "name": title || "O melhor conteúdo da programação web está aqui | A DEV IN DEV",
      "author": {
        "@type": "Person",
        "name": "Claudimar Bezerra",
        "url": pageURL || process.env.NEXT_PUBLIC_SITE_URL
      }
    }
    return {
      __html: JSON.stringify(jsonLD)
    }
  }

  return (
    <Head>
      <title>{title || `O melhor conteúdo da programação web está aqui | A DEV IN DEV`}</title>

      <link
        rel="canonical"
        href={pageURL || process.env.NEXT_PUBLIC_SITE_URL}
        key="canonical"
      />

      {metaData.map(({ name, content, property }, i) => {
        if(name !== undefined) {
          return <meta
            key={i}
            name={name}
            content={content}
          />
        }
        
        if(property !== undefined) {
          return <meta
            key={i}
            property={property}
            content={content}
          />
        }
      })}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addWebsiteSchema()}
        key="website-jsonld"
      />
    </Head>
  )
}

export { SEO }